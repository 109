var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('aad-title',[_vm._v("Notes")]),(_vm.allNotes && _vm.allNotes.length > 0)?_c('div',{staticClass:"print-hide"},[_c('a',{staticClass:"btn btn-primary-solid my-2 mr-2",attrs:{"href":"#"},on:{"click":_vm.printNotes}},[_vm._v("Print Notes")]),_c('a',{staticClass:"btn btn-primary-solid my-2",attrs:{"href":"#"},on:{"click":_vm.emailNotes}},[_vm._v("Export via Email")]),_c('transition',{attrs:{"name":"fade"}},[(_vm.emailExporting)?_c('p',{staticClass:"d-block my-2 font-italic"},[_vm._v(" Opening export in your default email application! ")]):_vm._e()]),_c('hr')],1):_vm._e(),(_vm.sessionNotes && _vm.sessionNotes.length > 0)?_c('div',[_c('h2',[_vm._v("Session Notes:")]),_vm._l((_vm.sessionNotes),function(note){return _c('div',{key:note.id},[_c('div',{staticClass:"meeting-app-info-block w-100"},[_c('div',{staticClass:"meeting-app-info-header row"},[_c('router-link',{staticClass:"text-link-light-bg",attrs:{"to":{
              name: 'SessionDetails',
              params: { id: note.LinkId },
            }}},[_c('h4',[_vm._v(_vm._s(note.Title))])])],1),_c('div',{staticClass:"meeting-app-info-body"},[_vm._v(" "+_vm._s(note.NoteBody)+" ")])])])})],2):_vm._e(),(
      _vm.speakerNotes &&
      _vm.sessionNotes &&
      _vm.sessionNotes.length > 0 &&
      _vm.speakerNotes.length > 0
    )?_c('hr',{staticClass:"my-4"}):_vm._e(),(_vm.speakerNotes && _vm.speakerNotes.length > 0)?_c('div',[_c('h2',[_vm._v("Speaker Notes:")]),_vm._l((_vm.speakerNotes),function(note){return _c('div',{key:note.id},[_c('div',{staticClass:"meeting-app-info-block w-100"},[_c('div',{staticClass:"meeting-app-info-header row"},[_c('router-link',{staticClass:"text-link-light-bg",attrs:{"to":{
              name: 'SpeakerDetails',
              params: { id: note.LinkId },
            }}},[_c('h4',[_vm._v(_vm._s(note.Title))])])],1),_c('div',{staticClass:"meeting-app-info-body"},[_vm._v(" "+_vm._s(note.NoteBody)+" ")])])])})],2):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }